import React from 'react';
import PropTypes from "prop-types";
import { Box, Stack, Sheet, Typography, Avatar, Chip } from '@mui/joy';
import DOMPurify from 'dompurify';

import { Profile } from "domain";

import ProfileActionMenu from './ProfileActionMenu';
import CommonUtils from "utils/CommonUtils";

const ProfileDetails = ({ profile }) => {
    const {name, type, bio, tagline, status} = profile;
    const active = status === Profile.STATUS_ACTIVE;
    const bioHtml = DOMPurify.sanitize(bio ?? '');

    return (
        <Box
            display="flex"
            flexDirection={{xs: 'column', sm: 'row'}}
            gap={2}
            alignItems={{xs: 'center', sm: 'flex-start'}}
        >
            <Box position="relative">
                <Avatar
                    sx={{
                        width: 100,
                        height: 100,
                        mb: 2,
                        filter: active ? 'none' : 'grayscale(100%)',
                        fontSize: 42,
                    }}
                    color={active ? 'primary' : 'neutral'}
                    variant="soft"
                    src={profile.picUrl}
                >
                    {CommonUtils.getInitials(profile.name)}
                </Avatar>
            </Box>
            <Stack gap={2} flexGrow={1}>
                <Box display="flex" flexDirection="row">
                    <Typography level="h4" component="h1">{name}</Typography>
                    <Box ml={2} flexGrow={1}>
                        <Chip
                            color={type === Profile.TYPE_ADMIN ? "danger" : "primary"}
                            disabled={!active}
                            variant="soft"
                            sx={{"--Chip-paddingInline": "24px"}}
                        >
                            {Profile.TYPE_LABELS[type]}
                        </Chip>
                    </Box>
                    {!active && <Box>
                        <Chip color="danger" variant="soft" sx={{"--Chip-paddingInline": "24px"}}>Inactive</Chip>
                    </Box>}
                    <ProfileActionMenu profile={profile}/>
                </Box>
                <Typography variant="title-md">{tagline}</Typography>
                {bioHtml !== '' &&
                    <Sheet sx={{px: 2}}>
                        <div dangerouslySetInnerHTML={{ __html: bioHtml }} />
                    </Sheet>
                }
            </Stack>
        </Box>
    );
};

ProfileDetails.propTypes = {
    profile: PropTypes.instanceOf(Profile)
};

export default ProfileDetails;
