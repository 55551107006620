import React from 'react';
import PropTypes from 'prop-types';
import {Material} from "domain";

const ImageDisplay = ({ material }) => (
    <img
        src={material?.attachment?.url()}
        alt={material?.data?.name ?? material?.attachment?.name()}
        style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            margin: '0 auto',
        }}
    />
);

ImageDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default ImageDisplay;
