// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    --sjs-font-family: var(--joy-fontFamily-body);
    --primary: var(--joy-palette-primary-softBg);
    --foreground: var(--joy-palette-text-secondary);
    --sjs-editorpanel-backcolor: var(--joy-palette-background-surface);

    --sjs-general-backcolor-dim: var(--joy-palette-background-body);
    --sjs-general-backcolor: var(--joy-palette-neutral-softBg);
    --sjs-general-forecolor: var(--joy-palette-text-secondary);

    --sjs-primary-backcolor: var(--joy-palette-primary-solidBg);
    --sjs-primary-backcolor-dark: var(--joy-palette-primary-solidHoverBg);
    --sjs-primary-forecolor: var(--joy-palette-primary-solidColor);
}

textarea.svc-json-editor-tab__content-area {
    min-height: 320px;
    min-width: 100%;
    resize: vertical;
}

.spg-search-editor_container {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/material/display/FormDisplay.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,4CAA4C;IAC5C,+CAA+C;IAC/C,kEAAkE;;IAElE,+DAA+D;IAC/D,0DAA0D;IAC1D,0DAA0D;;IAE1D,2DAA2D;IAC3D,qEAAqE;IACrE,8DAA8D;AAClE;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":["* {\n    --sjs-font-family: var(--joy-fontFamily-body);\n    --primary: var(--joy-palette-primary-softBg);\n    --foreground: var(--joy-palette-text-secondary);\n    --sjs-editorpanel-backcolor: var(--joy-palette-background-surface);\n\n    --sjs-general-backcolor-dim: var(--joy-palette-background-body);\n    --sjs-general-backcolor: var(--joy-palette-neutral-softBg);\n    --sjs-general-forecolor: var(--joy-palette-text-secondary);\n\n    --sjs-primary-backcolor: var(--joy-palette-primary-solidBg);\n    --sjs-primary-backcolor-dark: var(--joy-palette-primary-solidHoverBg);\n    --sjs-primary-forecolor: var(--joy-palette-primary-solidColor);\n}\n\ntextarea.svc-json-editor-tab__content-area {\n    min-height: 320px;\n    min-width: 100%;\n    resize: vertical;\n}\n\n.spg-search-editor_container {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
