import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {Card, CardContent, CardActions, Button, IconButton, ModalDialog, Modal, ModalClose, Typography} from '@mui/joy';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Comment';

import { EnrollmentRequest } from 'domain';
import enrollmentRequestActions from 'actions/enrollmentRequestActions';
import CommonUtils from 'utils/CommonUtils';
import ProfileLabel from "components/core/ProfileLabel";

const EnrollmentRequestListItem = ({ enrollmentRequest }) => {
    const dispatch = useDispatch();
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    const handleApprove = () => {
        enrollmentRequest.status = EnrollmentRequest.STATUS_ACCEPTED;
        CommonUtils.dispatcher(
            enrollmentRequestActions.save(enrollmentRequest),
            dispatch,
            'Enrollment Accepted',
            'Failed to update enrollment request.'
        );
    };

    const handleReject = () => {
        enrollmentRequest.status = EnrollmentRequest.STATUS_REJECTED;
        CommonUtils.dispatcher(
            enrollmentRequestActions.save(enrollmentRequest),
            dispatch,
            'Enrollment Rejected',
            'Failed to update enrollment request.'
        );
    };

    const handleMessageClick = () => {
        setIsMessageModalOpen(true);
    };

    const handleMessageModalClose = () => {
        setIsMessageModalOpen(false);
    };

    return (
        <>
            <Card variant="outlined" orientation="horizontal">
                <CardContent>
                    <ProfileLabel profile={enrollmentRequest.requester} />
                </CardContent>
                <CardActions sx={{p: 0}}>
                    {enrollmentRequest.message && (
                        <IconButton color="neutral" variant="soft" onClick={handleMessageClick}>
                            <MessageIcon />
                        </IconButton>
                    )}

                    <Button
                        startDecorator={<CheckIcon />}
                        onClick={handleApprove}
                        color="success"
                        variant="soft"
                        disabled={enrollmentRequest.status === EnrollmentRequest.STATUS_ACCEPTED}
                    >
                    Accept
                    </Button>

                    <Button
                        startDecorator={<CloseIcon />}
                        onClick={handleReject}
                        color="danger"
                        variant="soft"
                        disabled={enrollmentRequest.status === EnrollmentRequest.STATUS_REJECTED}
                    >
                    Reject
                    </Button>
                </CardActions>
            </Card>
            <Modal open={isMessageModalOpen} onClose={handleMessageModalClose}>
                <ModalDialog size="md">
                    <ModalClose />
                    <Typography level="title-sm" sx={{ p: 0 }} color="neutral">
                       Attached Message:
                    </Typography>
                    <Typography level="body-md" color="primary" component="blockquote" sx={{ px: 2 }}>
                        {enrollmentRequest.message}
                    </Typography>
                </ModalDialog>
            </Modal>
        </>
    );
};

EnrollmentRequestListItem.propTypes = {
    enrollmentRequest: PropTypes.instanceOf(EnrollmentRequest).isRequired,
};

export default EnrollmentRequestListItem;
