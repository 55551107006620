import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogActions, Button } from '@mui/joy';

import EnrollmentRequestForm from './EnrollmentRequestForm';

const EnrollmentRequestFormDialog = ({ open, onClose, onSubmit }) => {
    const [message, setMessage] = useState('');

    const handleChange = (message) => {
        setMessage(message);
    };

    const handleSubmit = () => {
        onSubmit(message);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <EnrollmentRequestForm
                    message={message}
                    onChange={handleChange}
                />
                <DialogActions>
                    <Button variant="solid" onClick={handleSubmit}>Submit Request</Button>
                    <Button variant="plain" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

EnrollmentRequestFormDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EnrollmentRequestFormDialog;
