import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Chip} from "@mui/joy";

import { Message, Profile } from "domain";
import CommonUtils from "utils/CommonUtils";
import ProfileLabel from "components/core/ProfileLabel";

const MessageTableRow = ({ message, me, onClick }) => {
    const { from, to, group, sent, subject, status } = message;

    const fromProfile = from && new Profile(from?.get('profile'));
    const toProfile = to && new Profile(to?.get('profile'));
    const toGroup = group?.get('name');

    const isUnread = status === Message.STATUS_UNREAD && fromProfile?.id !== me?.id;

    const styles = {
        tr: {
            cursor: 'pointer',
            backgroundColor: isUnread ? 'var(--joy-palette-primary-softBg)' : 'inherit',
            fontWeight: 'var(--joy-fontWeight-lg)',
        },
        td: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
    };

    const meLabel = (
        <Typography variant="body-sm" color="neutral">ME</Typography>
    )

    const fromLabel = from && me && (fromProfile.id === me?.id ? meLabel : <ProfileLabel profile={fromProfile} />)
    const toLabel = to && me && (toProfile.id === me?.id ? meLabel : <ProfileLabel profile={toProfile} />)
    const groupChip = toGroup && <Chip>{toGroup}</Chip>

    return (
        <tr style={styles.tr} onClick={() => onClick(message)}>
            <td style={styles.td}>{fromLabel}</td>
            <td style={styles.td}>{toLabel} {groupChip}</td>
            <td style={styles.td}>{CommonUtils.formatDateTime(sent)}</td>
            <td style={styles.td}>{subject}</td>
        </tr>
    );
};

MessageTableRow.propTypes = {
    message: PropTypes.instanceOf(Message).isRequired,
    me: PropTypes.instanceOf(Profile).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MessageTableRow;
