import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/joy';


import SectionListItem from './SectionListItem';
import SectionListAdd from "./SectionListAdd";
import {Course} from "domain";

const SectionList = ({ sections, course, onClick, enableAdd }) => {

    if ((!sections || sections.length === 0) && !enableAdd) {
        return (
            <Typography level="body-sm" my={2} color="neutral">
                No sections available.
            </Typography>
        );
    }

    return (
        <Box display="flex" flexWrap="wrap" gap={2}>
            {sections?.map((section) => (
                <SectionListItem key={section.id} section={section} onClick={onClick} />
            ))}
            {enableAdd && <SectionListAdd course={course} />}
        </Box>
    );
}

SectionList.propTypes = {
    sections: PropTypes.array,
    course: PropTypes.instanceOf(Course).isRequired,
    onClick: PropTypes.func,
    enableAdd: PropTypes.bool,
};

SectionList.defaultProps = {
    sections: null,
    onClick: null,
    enableAdd: false,
};

export default SectionList;
