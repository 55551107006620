import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Box, Button, Typography} from "@mui/joy";
import ProfileIcon from "@mui/icons-material/Person";

import profileActions from 'actions/profileActions';
import ProfileForm from "components/profile/ProfileForm";
import OverlayModal from "components/core/OverlayModal";
import CommonUtils from 'utils/CommonUtils';
import {Profile} from "domain";

const ProfileFormInterrupt = () => {
    const dispatch = useDispatch();
    const me = useSelector(({ auth }) => auth.me);
    const [myProfile, setProfile] = useState(new Profile());
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setProfile(myProfile.clone().set(key, val ?? evt.target.value))
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await myProfile.inspect();

            CommonUtils.dispatcher(
                profileActions.save(myProfile
                    .set('status', Profile.STATUS_ACTIVE)
                    .set('type', Profile.TYPE_STUDENT)
                    .set('user', me.toPointer()),
                true
                ),
                dispatch,
                'Profile Saved',
                'Failed To Save Profile'
            );
        } catch (err) {
            setErrors(err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {}));
        }
    };

    return (
        <OverlayModal maxWidth="md">
            <Avatar sx={{mt: 2}} color="primary">
                <ProfileIcon/>
            </Avatar>
            <Typography level="title-lg">Profile Setup</Typography>
            <Typography level="body-sm" textAlign="left">Welcome to OFALL</Typography>
            <Typography level="body-sm" textAlign="left">Tell us about yourself to begin using the system.</Typography>
            <Box my={2} width="100%">
                <ProfileForm profile={myProfile} errors={errors} onChange={handleChange} onSave={handleSubmit} />
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="solid"
                onClick={handleSubmit}
                disabled={!myProfile.isSavable()}
            >
                Save
            </Button>
        </OverlayModal>
    );
}

ProfileFormInterrupt.propTypes = {};

export default ProfileFormInterrupt;
