import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, FormLabel, Input, FormHelperText } from '@mui/joy';

import { Section } from 'domain';
import RichTextarea from "components/core/RichTextarea";

const SectionForm = ({ onSave, onChange, section, errors }) => {
    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <FormControl error={!!errors.name}>
                <FormLabel required>Name</FormLabel>
                <Input
                    placeholder="Enter the section name."
                    value={section.name}
                    onChange={onChange('name')}
                    error={!!errors.name}
                />
                <FormHelperText>{errors.name}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.summary}>
                <FormLabel required>Summary</FormLabel>
                <Input
                    placeholder="Enter a section summary."
                    value={section.summary}
                    onChange={onChange('summary')}
                    error={!!errors.summary}
                />
                <FormHelperText>{errors.summary}</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel>Description</FormLabel>
                <RichTextarea
                    value={section.description}
                    onChange={(markdown) => {
                        onChange('description')(null, markdown);
                    }}
                />
            </FormControl>
        </Stack>
    );
};

SectionForm.propTypes = {
    section: PropTypes.instanceOf(Section),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default SectionForm;
