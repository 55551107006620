import React from 'react';
import PropTypes from 'prop-types';
import {Stack} from '@mui/joy';

import { EnrollmentRequest } from 'domain';
import EnrollmentRequestListItem from './EnrollmentRequestListItem';

const EnrollmentRequestList = ({ enrollmentRequests }) => (
    <Stack spacing={2}>
        {enrollmentRequests.map((request, index) => (
            <EnrollmentRequestListItem key={index} enrollmentRequest={request} />
        ))}
    </Stack>
)

EnrollmentRequestList.propTypes = {
    enrollmentRequests: PropTypes.arrayOf(PropTypes.instanceOf(EnrollmentRequest)).isRequired,
};

export default EnrollmentRequestList;
