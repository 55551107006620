import React from 'react';
import PropTypes from 'prop-types';
import { Material } from 'domain';
import {Avatar, Card, CardContent, Typography} from '@mui/joy';
import MaterialIcon from "@mui/icons-material/BrokenImage";

const MaterialListItem = ({ material, onClick }) => {
    const Icon = Material.TYPES_ICONS[material.type] ?? MaterialIcon;
    return (
        <Card
            variant="outlined"
            color="neutral"
            orientation="horizontal"
            sx={{
                width: '100%',
                cursor: onClick ? 'pointer' : 'default',
                '&:hover': onClick ? { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' } : null,
            }}
            onClick={onClick ? () => onClick(material) : null}
        >
            <Avatar
                variant="soft"
                color="neutral"
                sx={{
                    width: 48,
                    height: 48,
                    ml: 1,
                    mr: 2,
                }}
            >
                <Icon color="neutral" />
            </Avatar>
            <CardContent>
                <Typography level="title-lg" color="neutral">{material.name}</Typography>
                <Typography level="body-sm" color="neutral">{Material.TYPE_LABELS[material.type]}</Typography>
            </CardContent>
        </Card>
    );
};

MaterialListItem.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onClick: PropTypes.func,
};

export default MaterialListItem;
