import { Material } from 'domain';

import AttachmentDisplay from 'components/material/display/AttachmentDisplay';
import DocumentDisplay from "components/material/display/DocumentDisplay";
import FormDisplay from "components/material/display/FormDisplay";
import ImageDisplay from 'components/material/display/ImageDisplay';
import PdfDisplay from "components/material/display/PdfDisplay";
import VideoDisplay from "components/material/display/VideoDisplay";
import WebURLDisplay from "components/material/display/WebURLDisplay";

export default {
    [Material.TYPE_ATC]: AttachmentDisplay,
    [Material.TYPE_DOC]: DocumentDisplay,
    [Material.TYPE_FRM]: FormDisplay,
    [Material.TYPE_IMG]: ImageDisplay,
    [Material.TYPE_PDF]: PdfDisplay,
    [Material.TYPE_WVD]: VideoDisplay,
    [Material.TYPE_WEB]: WebURLDisplay,
};
