import React from 'react';
import PropTypes from 'prop-types';
import {
    Stack, Sheet, Typography, Select, Option, Input, FormControl, FormLabel, Switch,
} from '@mui/joy';
import WarningIcon from '@mui/icons-material/Info';

import { Material } from 'domain';

const DEFAULT_DATA = {
    mode: 'button',
    url: '',
    label: '',
    outlined: false,
};

const WebURLForm = ({ material, onChange }) => {
    const incomingData = material.data ?? DEFAULT_DATA;
    const data = {...DEFAULT_DATA, ...incomingData};
    const { url, mode, label } = data

    const handleChangeMode = (event, val) => {
        onChange('data', {...data, mode: val ?? DEFAULT_DATA['mode'] });
    };

    const handleChangeURL = (event) => {
        onChange('data', {...data, url: event?.target?.value ?? DEFAULT_DATA['url'] });
    };

    const handleChangeLabel = (event) => {
        onChange('data', {...data, label: event?.target?.value ?? DEFAULT_DATA['label'] });
    };

    const handleToggledOutlined = () => {
        onChange('data', {...data, outlined: !data.outlined });
    };


    return (
        <Stack gap={2}>
            <Typography level="body-sm">
                A web URL material can be used to either link a user to an external page or embed a page within your
                app. Select &quot;embed&quot; mode to embed the page within your app. Note, that some browsers place
                restrictions on what can be embedded. You can also select &quot;button&quot; mode, which gives you
                a button and a label to create a link that will open the page in a new browser window.
            </Typography>


            <FormControl>
                <FormLabel>Mode</FormLabel>
                <Select
                    label="Mode"
                    value={mode}
                    onChange={handleChangeMode}
                >
                    <Option value="button">Button</Option>
                    <Option value="link">Direct Link</Option>
                    <Option value="embed">Embed</Option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>URL</FormLabel>
                <Input
                    placeholder="Enter the URL to the web resource."
                    value={url}
                    onChange={handleChangeURL}
                />
            </FormControl>

            {mode === 'button' && (
                <FormControl>
                    <FormLabel>Button Label</FormLabel>
                    <Input
                        placeholder="Provide the button's label."
                        value={label}
                        onChange={handleChangeLabel}
                    />
                </FormControl>
            )}
            {mode === 'embed' && (
                <>
                    <Typography
                        component="outlined"
                        startDecorator={
                            <Switch
                                checked={data.outlined}
                                onChange={handleToggledOutlined}
                                name="outlined"
                            />
                        }>
                        <Stack>
                            Outlined?
                        </Stack>
                    </Typography>
                    <Sheet
                        variant="soft"
                        color="neutral"
                        sx={{ p: 2}}
                    >
                        <Typography level="title-md" component="h3" color="neutral">
                            <WarningIcon sx={{ mr: 1, verticalAlign: 'top', fontSize: 24 }} color="neutral" />
                        Please Note
                        </Typography>
                        <Typography level="body-sm">
                        For security reasons, some sites do not allow themselves to be embedded inside external
                        apps/sites. Only embed pages you are authorized to embed. If the page does not show up in your
                        course, please contact the website owner to set the appropriate &quot;X-Frame-Options&quot; in
                        the target page.
                        </Typography>
                    </Sheet>
                </>
            )}
        </Stack>
    );
};

WebURLForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default WebURLForm;
