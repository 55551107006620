import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import DOMPurify from "dompurify";
import {Box, Button, Chip, Sheet, Stack, Typography, IconButton} from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import EnrollIcon from '@mui/icons-material/HowToReg';

import { Course, Profile, SectionArray, EnrollmentRequest } from "domain";
import CommonUtils from "utils/CommonUtils";
import enrollmentRequestActions from "actions/enrollmentRequestActions";
import Header from "components/core/Header";
import ProfileChip from "components/core/ProfileChip";
import LanguageLabel from "components/core/LanguageLabel";
import SectionList from 'components/section/SectionList';
import EnrollmentRequestFormDialog from 'components/enrollmentRequest/EnrollmentRequestFormDialog';
import EnrollmentRequestList from "components/enrollmentRequest/EnrollmentRequestList";

const CourseDetails = ({course, sections, onEdit}) => {
    const { name, description, tagline, language, status } = course;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const me = useSelector(({ profile }) => profile.me);
    const enrollmentRequests = useSelector(({ enrollmentRequest }) => enrollmentRequest.listByCourse);
    const [isEnrollmentDialogOpen, setEnrollmentDialogOpen] = useState(false);

    const tutorProfile = new Profile(course.get('tutor')?.get('profile'));
    const descriptionHtml = DOMPurify.sanitize(description ?? '');
    const inactive = status === Course.STATUS_INACTIVE;
    const closed = status === Course.STATUS_CLOSED;
    const isEditable = onEdit != null && (me?.type === Profile.TYPE_ADMIN || me?.id === tutorProfile?.id);

    const handleEnrollClick = () => {
        setEnrollmentDialogOpen(true);
    };

    const handleSignup = () => {
        navigate('/signup')
    }

    const handleEnrollmentRequestSubmit = (message) => {
        CommonUtils.dispatcher(
            enrollmentRequestActions.save(new EnrollmentRequest({
                course,
                message,
                requester: me,
                status: EnrollmentRequest.STATUS_PENDING,
            })),
            dispatch,
            'Enrollment Request Sent',
            'Failed to send enrollment request.'
        )

        setEnrollmentDialogOpen(false);
    };

    return (
        <Stack gap={2}>
            <Header>
                {name}
                {inactive && <Chip color="danger">Inactive</Chip>}
                {closed && <Chip color="warning">Closed</Chip>}
                {isEditable && (
                    <Box>
                        <IconButton onClick={onEdit}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                )}
                {isAuthorized && !isEditable && (
                    <Box>
                        <Button onClick={handleEnrollClick}>
                            <EnrollIcon sx={{mr: 1}} />
                            Enroll
                        </Button>
                    </Box>
                )}
                {!isAuthorized && !isEditable && (
                    <Box>
                        <Button onClick={handleSignup}>
                            <EnrollIcon sx={{mr: 1}} />
                            Sign Up to Enroll
                        </Button>
                    </Box>
                )}
            </Header>
            <Box display="flex" gap={1} justifyContent="space-between">
                <LanguageLabel language={language} />
                <ProfileChip profile={tutorProfile} disabled={!isAuthorized} />
            </Box>
            <Typography level="body-md" color="neutral">{tagline}</Typography>
            <Sheet variant="soft" sx={{ px: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            </Sheet>

            {((sections && sections.length > 0) || isEditable) && (<>
                <Typography level="title-lg" mt={2}>
                    Sections
                </Typography>
                <SectionList
                    course={course}
                    sections={sections}
                    onClick={isEditable ? (section) => {navigate(`/course/${course.id}/section/${section.id}`)} : null}
                    enableAdd={isEditable}
                />
            </>)}

            {isEditable && enrollmentRequests.length > 0 && (<>
                <Typography level="title-lg" mt={2}>
                    Enrollment Requests
                </Typography>
                <EnrollmentRequestList enrollmentRequests={enrollmentRequests}/>
            </>)}
            <EnrollmentRequestFormDialog
                open={isEnrollmentDialogOpen}
                onClose={() => setEnrollmentDialogOpen(false)}
                onSubmit={handleEnrollmentRequestSubmit}
            />
        </Stack>
    );
}

CourseDetails.propTypes = {
    course: PropTypes.instanceOf(Course).isRequired,
    sections: PropTypes.instanceOf(SectionArray),
    onEdit: PropTypes.func,
};

CourseDetails.defaultProps = {
    sections: null,
    onEdit: null,
};

export default CourseDetails;
