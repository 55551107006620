import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import { Sheet, Grid, Input, Select, Option, FormLabel, FormControl, ListItemDecorator, Avatar } from '@mui/joy';
import { Course } from "domain";
import { debounce } from 'lodash';

import { useDebounce } from 'hooks/useDebounce';

const CourseFilter = ({ onChange }) => {
    const [text, setText] = useState('');
    const [language, setLanguage] = useState('');
    const [status, setStatus] = useState([Course.STATUS_ACTIVE]);
    const debouncedText = useDebounce(text, 500);

    useEffect(() => {
        onChange({ text: debouncedText, language, status });
    }, [debouncedText, language, status]);

    const debouncedApplyFiltersForText = useCallback(debounce(() => {
        onChange({ text, language, status });
    }, 300), [text, language, status]);

    const handleTextChange = (e) => setText(e.target.value);

    const handleStatusChange = (e, value) => setStatus(typeof value === 'string' ? value.split(',') : value);;

    const handleLanguageChange = (e, value) => setLanguage(value);

    // Clean up the debounced function on component unmount
    useEffect(() => {
        return () => debouncedApplyFiltersForText.cancel();
    }, []);

    return (
        <Sheet variant="soft" sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={4}>
                    <FormControl>
                        <FormLabel>Search</FormLabel>
                        <Input
                            placeholder="Search"
                            value={text}
                            onChange={handleTextChange}
                        />
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={4}>
                    <FormControl>
                        <FormLabel>Language</FormLabel>
                        <Select
                            value={language}
                            placeholder="Any"
                            onChange={handleLanguageChange}
                        >
                            {Course.LANGUAGES.map((languageCode) => (
                                <Option key={languageCode} value={languageCode}>
                                    <ListItemDecorator>
                                        <Avatar size="sm" src={`https://unpkg.com/language-icons/icons/${languageCode.toLowerCase()}.svg`} />
                                    </ListItemDecorator>
                                    {Course.LANGUAGE_LABELS[languageCode]}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid xs={12} sm={4}>
                    <FormControl>
                        <FormLabel>Status</FormLabel>
                        <Select
                            value={status}
                            multiple
                            onChange={handleStatusChange}
                            placeholder="All"
                        >
                            {Course.STATUSES.map((statusKey) => (
                                <Option key={statusKey} value={statusKey}>{Course.STATUS_LABELS[statusKey]}</Option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Sheet>
    );
};

CourseFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default CourseFilter;
