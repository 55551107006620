import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/joy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import { Material } from 'domain';
import materialActions from 'actions/materialActions';
import CommonUtils from "utils/CommonUtils";

const MaterialActionMenu = ({ material, onEdit }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEdit();
        handleMenuClose();
    };

    const handleToggleStatus = () => {
        const newStatus = material.status === Material.STATUS_ACTIVE
            ? Material.STATUS_INACTIVE
            : Material.STATUS_ACTIVE;
        CommonUtils.dispatcher(
            materialActions.save(material.set('status', newStatus)),
            dispatch,
            newStatus === Material.STATUS_ACTIVE ? 'Material Activated' : 'Material Archived',
            'Failed To Update Material'
        );
        handleMenuClose();
    };

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>
                    <EditIcon /> Edit
                </MenuItem>
                <MenuItem onClick={handleToggleStatus}>
                    {material.status === Material.STATUS_ACTIVE ? <ToggleOffIcon /> : <ToggleOnIcon />}
                    {material.status === Material.STATUS_ACTIVE ? 'Archive' : 'Activate'}
                </MenuItem>
            </Menu>
        </>
    );
};

MaterialActionMenu.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default MaterialActionMenu;
