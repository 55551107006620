import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel} from "@mui/joy";

import { Material } from 'domain';
import RichTextarea from "components/core/RichTextarea";

const DEFAULT_DATA = {
    mode: 'MD',
    content: '',
};

const DocumentForm = ({
    material, onChange,
}) => {
    const incomingData = material.data ?? DEFAULT_DATA;
    const data = {...DEFAULT_DATA, ...incomingData};
    const { content } = data

    const handleChange = (key) => (val) => {
        onChange('data', {...data, [key]: val ?? DEFAULT_DATA[key] });
    };

    return (
        <FormControl>
            <FormLabel>Content</FormLabel>
            <RichTextarea value={content} onChange={handleChange('content')} />
        </FormControl>
    );
};

DocumentForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DocumentForm;
