import React from 'react';
import PropTypes from 'prop-types';
import {Stack, FormControl, FormLabel, Input, Select, Option, ListItemDecorator, Box} from '@mui/joy';

import { Material} from 'domain';
import subForms from './form';
import MaterialIcon from "@mui/icons-material/BrokenImage";

const MaterialForm = ({ material, onChange }) => {
    const TypeForm = subForms[material.type];

    const handleChange = (key, val) => {
        onChange(key)(null, val);
    };

    const renderTypeValue = ({value}) => {
        const Icon = Material.TYPES_ICONS[value] ?? MaterialIcon;
        return (
            <Box display="flex" alignItems="center" gap={1}>
                <Icon />
                {Material.TYPE_LABELS[value]}
            </Box>
        );
    }

    return (
        <Stack component="form" gap={2}>
            <FormControl>
                <FormLabel required>Name</FormLabel>
                <Input value={material.name} onChange={onChange('name')} />
            </FormControl>
            <FormControl>
                <FormLabel required>Type</FormLabel>
                <Select
                    value={material.type}
                    onChange={onChange('type')}
                    renderValue={renderTypeValue}
                >
                    {Material.TYPES.map((type) => {
                        const Icon = Material.TYPES_ICONS[type] ?? MaterialIcon;
                        return (
                            <Option key={type} value={type}>
                                <ListItemDecorator>
                                    <Icon />
                                </ListItemDecorator>
                                {Material.TYPE_LABELS[type]}
                            </Option>
                        )
                    })}
                </Select>
            </FormControl>
            <TypeForm material={material} onChange={handleChange} />
        </Stack>
    );
};

MaterialForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MaterialForm;
