import React from 'react';
import { Skeleton, Stack} from "@mui/joy";

const CourseDetailsSkeleton = () => (
    <Stack gap={2} flexGrow={1}>
        <Skeleton width="60%" height={48} variant="rectangular"/>
        <Skeleton width="100%" height={48} variant="rectangular"/>
        <Skeleton width="100%" height={400} variant="rectangular"/>
    </Stack>

);

CourseDetailsSkeleton.propTypes = {};

export default CourseDetailsSkeleton;
