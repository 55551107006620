import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { AspectRatio } from '@mui/joy';
import { Material } from "domain";

const VideoDisplay = ({ material }) => (
    <AspectRatio ratio="16/9" sx={{ borderRadius: 'sm' }}>
        <ReactPlayer
            url={material.data?.url}
            light={material.data?.light}
            controls={material.data?.controls}
            loop={material.data?.loop}
            width="100%"
            height="100%"
        />
    </AspectRatio>
);


VideoDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default VideoDisplay;
