import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, ModalClose, Button } from '@mui/joy';

import { Section } from "domain";
import SectionForm from './SectionForm';

const SectionFormDialog = ({ open, onClose, onSave, section }) => {
    const [mySection, setSection] = useState(new Section(section));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setSection(mySection.set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await mySection.inspect();
            onSave(mySection);
        } catch (err) {
            const newErrors = err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
        }
    };

    const handleClose = () => {
        mySection.reset();
        setErrors({});
        onClose();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                layout="center"
                variant="soft"
                aria-labelledby="modal-title"
                maxWidth={720}
                sx={{width:'80vw'}}
            >
                <ModalClose onClick={handleClose} />
                <DialogTitle id="modal-title">
                    {section ? `Edit ${mySection.name || 'Section'}` : 'Add Section'}
                </DialogTitle>
                <DialogContent>
                    <SectionForm
                        onSave={handleSubmit}
                        onChange={handleChange}
                        section={mySection}
                        errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="primary" onClick={handleSubmit} disabled={!mySection.isSavable() || !mySection.isDirty()}>
                        Save
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

SectionFormDialog.propTypes = {
    section: PropTypes.instanceOf(Section),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default SectionFormDialog;
