import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, FormLabel, Textarea, Typography } from '@mui/joy';

const EnrollmentRequestForm = ({ onChange, message }) => {
    return (
        <Stack sx={{ gap: 2, width: '100%' }}>
            <Typography level="title-md" component="div">Course Enrollment Request</Typography>
            <Typography level="body-sm">
                To enroll in this course, please send a request that will be reviewed by the tutor.
                Include any relevant information that supports your enrollment.
            </Typography>

            <FormControl>
                <FormLabel>Message to Tutor</FormLabel>
                <Textarea
                    placeholder="Enter your message here"
                    value={message}
                    onChange={(e) => onChange(e.target.value)}
                    minRows={3}
                    maxRows={8}
                />
            </FormControl>
        </Stack>
    );
};

EnrollmentRequestForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    message: PropTypes.string,
};

export default EnrollmentRequestForm;
