import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar } from '@mui/joy';

import { Course, Profile } from "domain";
import CommonUtils from "utils/CommonUtils";
import LanguageLabel from "components/core/LanguageLabel";

const styles = {
    tr: {
        cursor: 'pointer'
    },
    td: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
};

const CourseTableRow = ({ course, onClick, hideTutor, highlighted }) => {
    const tutorProfile = new Profile(course.get('tutor')?.get('profile'));

    return (
        <tr
            style={{
                ...styles.tr,
                backgroundColor: highlighted ? 'var(--joy-palette-primary-100)' : null,
            }}
            onClick={() => onClick(course)}
        >
            <td style={styles.td}>{course.name}</td>
            <td>
                <LanguageLabel language={course.language} />
            </td>
            {!hideTutor && <td style={styles.td}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                    <Avatar
                        variant="soft"
                        size="sm"
                        src={tutorProfile.picUrl}
                    >
                        {CommonUtils.getInitials(tutorProfile.name)}
                    </Avatar>
                    <Box sx={{display: {xs: 'none', sm: 'inherit'}}}>
                        {tutorProfile.name}
                    </Box>
                </Box>
            </td>}
            <td style={styles.td}>{course.tagline}</td>
        </tr>
    );
}


CourseTableRow.propTypes = {
    course: PropTypes.instanceOf(Course).isRequired,
    onClick: PropTypes.func.isRequired,
    hideTutor: PropTypes.bool,
    highlighted: PropTypes.bool,
};

CourseTableRow.defaultProps = {
    hideTutor: false,
    highlighted: false,
};

export default CourseTableRow;
