// ProfileUpgradeRequestFormDialog.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogActions, Button } from '@mui/joy';

import ProfileUpgradeRequestForm from './ProfileUpgradeRequestForm';

const ProfileUpgradeRequestFormDialog = ({ open, onClose, onSubmit }) => {
    const [upgradeRequestMessage, setUpgradeRequestMessage] = useState('');

    const handleChange = (message) => {
        setUpgradeRequestMessage(message);
    };

    const handleSubmit = () => {
        onSubmit(upgradeRequestMessage);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <ProfileUpgradeRequestForm
                    message={upgradeRequestMessage}
                    onChange={handleChange}
                />
                <DialogActions>
                    <Button variant="solid" onClick={handleSubmit}>Submit Request</Button>
                    <Button variant="plain" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

ProfileUpgradeRequestFormDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProfileUpgradeRequestFormDialog;
