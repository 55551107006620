import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Switch, Typography, Input } from '@mui/joy';

import { Material } from 'domain';

const DEFAULT_DATA = {
    url: '',
    controls: true,
    light: true,
    loop: false,
};

const VideoForm = ({ material, onChange }) => {
    const data = { ...DEFAULT_DATA, ...material.data };
    const handleChange = (key, val) => {
        onChange('data', { ...DEFAULT_DATA, ...material.data, [key]: val });
    };

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            <Typography level="body1">
                Play a variety of URLs, including file paths, YouTube, Facebook, Twitch, SoundCloud, Streamable,
                Vimeo, Wistia, Mixcloud, DailyMotion, and Kaltura.
            </Typography>
            <Input
                placeholder="URL"
                value={data.url}
                onChange={(event) => handleChange('url', event.target.value)}
                fullWidth
            />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
            >
                <Typography
                    component="label"
                    startDecorator={
                        <Switch
                            checked={data.controls}
                            onChange={() => handleChange('controls', !data.controls)}
                            name="controls"
                        />
                    }>
                    <Stack>
                        Enable Full Controls?
                    </Stack>
                </Typography>

                <Typography
                    component="label"
                    startDecorator={
                        <Switch
                            checked={data.light}
                            onChange={() => handleChange('light', !data.light)}
                            name="light"
                        />
                    }>
                    <Stack>
                        Initially Light?
                    </Stack>
                </Typography>

                <Typography
                    component="label"
                    startDecorator={
                        <Switch
                            checked={data.loop}
                            onChange={() => handleChange('loop', !data.loop)}
                            name="loop"
                        />
                    }>
                    <Stack>
                        Loop Video?
                    </Stack>
                </Typography>

            </Stack>
        </Stack>
    );
};

VideoForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default VideoForm;
