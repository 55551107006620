import React from 'react';
import {Box, Skeleton, Stack} from "@mui/joy";

const MessageDetailsSkeleton = () => (
    <Stack gap={2} flexGrow={1}>
        <Skeleton width="60%" height={48} variant="rectangular"/>
        <Box display="flex" gap={1} justifyContent="space-between">
            <Box display="flex" gap={1}>
                <Skeleton width={32} height={32} variant="circular"/>
                <Skeleton width={128} height={32} variant="rectangular"/>
            </Box>
            <Box display="flex" gap={1}>
                <Skeleton width={32} height={32} variant="circular"/>
                <Skeleton width={128} height={32} variant="rectangular"/>
            </Box>
        </Box>

        <Skeleton width="100%" height={48} variant="rectangular"/>
        <Skeleton width="100%" height={400} variant="rectangular"/>
    </Stack>
);

MessageDetailsSkeleton.propTypes = {};

export default MessageDetailsSkeleton;
