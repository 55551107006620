import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, Link } from '@mui/joy';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {CourseArray} from "domain";

import CourseTableRow from './CourseTableRow';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const CourseTable = ({ courses, onClick, hideTutor, selected }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headers = hideTutor ? [
        {field: 'name', label: 'Name', width: null},
        {field: 'language', label: 'Language', width: null},
        {field: 'description', label: 'Description', width: '40%'},
    ] : [
        {field: 'name', label: 'Name', width: null},
        {field: 'language', label: 'Language', width: null},
        {field: 'tutor', label: 'Tutor', width: null},
        {field: 'description', label: 'Description', width: '40%'},
    ]

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table hoverRow stickyHeader>
                <thead>
                    <tr>
                        {headers.map((th) => {
                            const active = orderBy === th.field;
                            return (
                                <th key={th.field} style={{ width: th.width }}>
                                    <Link
                                        variant="plain"
                                        color="neutral"
                                        underline="none"
                                        component="button"
                                        onClick={() => handleRequestSort(th.field)}
                                        endDecorator={<ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />}
                                        sx={{
                                            '& svg': {
                                                transition: '0.2s',
                                                transform:
                                                active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                            },
                                            '&:hover': { '& svg': { opacity: 1 } },
                                        }}
                                    >
                                        {th.label}
                                    </Link>

                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {stableSort(courses, getComparator(order, orderBy)).map((course) => (
                        <CourseTableRow
                            key={course.id}
                            course={course}
                            onClick={onClick}
                            hideTutor={hideTutor}
                            highlighted={selected && selected.id === course.id}
                        />
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

CourseTable.propTypes = {
    courses: PropTypes.instanceOf(CourseArray).isRequired,
    onClick: PropTypes.func.isRequired,
    hideTutor: PropTypes.bool,
    selected: PropTypes.instanceOf(Array),
};

CourseTable.defaultProps = {
    hideTutor: false,
    selected: null,
};

export default CourseTable;
