import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Avatar } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';

import MaterialFormDialog from "components/material/MaterialFormDialog";
import {Course, Material, Section} from "domain";
import CommonUtils from "utils/CommonUtils";
import materialActions from "actions/materialActions";

const MaterialListAdd = ({ section, course }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async (material) => {
        material.set('section', section);
        CommonUtils.dispatcher(
            materialActions.save(material),
            dispatch,
            'Material Created',
            'Failed To Create Material'
        ).then(() => {
            handleClose();
            navigate(`/course/${course.id}/section/${section.id}/${material.id}`);
        });
    };

    return (
        <>
            <Card
                variant="outlined"
                color="neutral"
                orientation="horizontal"
                sx={{
                    width: '100%',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                }}
                onClick={handleOpen}
            >
                <Avatar
                    variant="soft"
                    color="neutral"
                    sx={{
                        width: 48,
                        height: 48,
                        ml: 1,
                        mr: 2,
                    }}
                >
                    <AddIcon color="neutral" />
                </Avatar>
                <CardContent>
                    <Typography level="title-lg" color="neutral">
                        Add Material
                    </Typography>
                    <Typography level="body-sm" color="neutral">
                        Add a new material to this course.
                    </Typography>
                </CardContent>
            </Card>

            <MaterialFormDialog
                open={open}
                onClose={handleClose}
                onSave={handleSubmit}
                material={new Material({ course: section })}
            />
        </>
    );
};

MaterialListAdd.propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    course: PropTypes.instanceOf(Course).isRequired,
};

export default MaterialListAdd;
