import React from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Profile} from "domain";
import {Alert, Box, Button, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";
import PropTypes from "prop-types";
import SubscriptionIcon from "@mui/icons-material/CardMembership";

const SubscriptionNotice = ({ small }) => {
    const navigate = useNavigate();
    const me = useSelector(({ profile }) => profile.me);
    const color = me.billingStatus === Profile.BILLING_STATUS_DEACTIVATED ? 'danger' : 'warning';

    if (me.type !== Profile.TYPE_TUTOR || me.billingStatus === Profile.BILLING_STATUS_ACTIVE) {
        return null;
    }

    const handleClick = () => {
        navigate('/subscription');
    }

    if (me.billingStatus === Profile.BILLING_STATUS_CANCELED) {
        return (
            <Alert
                startDecorator={small ? null : <WarningIcon fontSize="xl4" color={color} />}
                variant="soft"
                color={color}
                sx={{mb:2}}
            >
                <Box width="100%" component="div">
                    <Typography level={small ? "title-sm" : "title-lg"} color={color}>
                        Subscription Canceled
                    </Typography>
                    <Typography level={small ? "body-xs" : "body-md"} variant="plain" color={color}>
                        {small ? '' : 'Your subscription has been canceled. Please reactivate your subscription to avoid deactivation.'}
                    </Typography>
                    <Box align="center" mt={1}>
                        <Button
                            startDecorator={small ? <SubscriptionIcon fontSize="small" /> : null}
                            size={small ? "sm" : "md"}
                            color={color}
                            variant="solid"
                            fullWidth={small}
                            onClick={handleClick}
                        >
                            {small ? 'Subscribe' : 'Manage Subscription'}
                        </Button>
                    </Box>
                </Box>
            </Alert>
        )
    }

    return (
        <Alert
            startDecorator={small ? null : <WarningIcon fontSize="xl4" color={color} />}
            variant="soft"
            color={color}
            sx={{mb:2}}
        >
            <Box width="100%" component="div">
                <Typography level={small ? "title-sm" : "title-lg"} color={color}>
                    Subscription Not Active
                </Typography>
                <Typography level={small ? "body-xs" : "body-md"} variant="plain" color={color}>
                    {small ? '' : 'Your subscription is not currently active. '}
                    {'Please activate your subscription to continue.'}
                </Typography>
                <Box align="center" mt={1}>
                    <Button
                        startDecorator={small ? <SubscriptionIcon fontSize="small" /> : null}
                        size={small ? "sm" : "md"}
                        color={color}
                        variant="solid"
                        fullWidth={small}
                        onClick={handleClick}
                    >
                        {small ? 'Subscription' : 'Manage Subscription'}
                    </Button>
                </Box>
            </Box>
        </Alert>
    )
};

SubscriptionNotice.propTypes = {
    small: PropTypes.bool,
};

SubscriptionNotice.defaultProps = {
    small: false,
};

export default SubscriptionNotice;
