import React from 'react';
import PropTypes from 'prop-types';
import {Material} from "domain";
import {Alert, Box, Button, Stack, Typography} from "@mui/joy";
import OpenIcon from "@mui/icons-material/OpenInNew";

const WebURLDisplay = ({material}) => {
    const { url, mode, label, outlined } = material.data;
    const cleanUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

    switch (mode) {
        case 'link':
            window.open(cleanUrl, '_blank', 'noopener,noreferrer');
            return null;

        case 'embed':
            return (
                <iframe
                    src={cleanUrl}
                    title="web viewer"
                    style={{
                        flexGrow: 1,
                        borderStyle: 'solid',
                        borderWidth: outlined ? 1 : 0,
                        borderRadius: outlined ? 'var(--joy-radius-md)' : null,
                        borderColor: outlined ? 'var(--variant-outlinedBorder, var(--joy-palette-primary-outlinedBorder, var(--joy-palette-primary-300, #97C3F0)))' : null,
                    }}
                >Loading...</iframe>
            );

        case 'button':
            return (
                <Box textAlign="center">
                    <Button
                        variant="solid"
                        color="primary"
                        href={cleanUrl}
                        size="lg"
                        target="_blank"
                        component="a"
                        startDecorator={<OpenIcon />}
                    >
                        {label ?? 'Open'}
                    </Button>
                </Box>
            );

        default:
            return (
                <Alert variant="soft" color="danger">
                    <Stack>
                        <Typography level="body-md" variant="plain" color="danger">
                            Unknown URL Mode
                        </Typography>
                    </Stack>
                </Alert>
            );
    }
}

WebURLDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default WebURLDisplay;
