import React from 'react';
import PropTypes from 'prop-types';
import {Material} from "domain";
import CommonUtils from "utils/CommonUtils";
import AttachmentDisplay from "components/material/display/AttachmentDisplay";

const PdfDisplay = ({ material }) => CommonUtils.isMobileDevice() ? (
    <AttachmentDisplay material={material} />
) : (
    <embed
        src={material.attachment.url()}
        style={{
            flexGrow: 1,
            width: '100%',
            height: '100%',
            minHeight: '60vh',
        }}
        alt="pdf"
        // eslint-disable-next-line react/no-unknown-property
        pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
    />
);

PdfDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default PdfDisplay;
