import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, ModalClose, Button } from '@mui/joy';

import { Profile } from "domain";

import ProfileForm from './ProfileForm';

const ProfileFormDialog = ({ open, onClose, onSave, profile }) => {
    const [myProfile, setProfile] = useState(new Profile(profile));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setProfile(myProfile.set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await myProfile.inspect();
            onSave(myProfile);
        } catch (err) {
            const newErrors = err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
        }
    };

    const handleClose = () => {
        myProfile.reset();
        setErrors({});
        onClose();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                layout="center"
                variant="soft"
                aria-labelledby="modal-title"
                maxWidth={720}
                sx={{width:'80vw'}}
            >
                <ModalClose onClick={handleClose} />
                <DialogTitle id="modal-title">
                    {profile ? `Edit ${myProfile.name || 'Profile'}` : 'Add Profile'}
                </DialogTitle>
                <DialogContent>
                    <ProfileForm
                        onSave={handleSubmit}
                        onChange={handleChange}
                        profile={myProfile}
                        errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="primary" onClick={handleSubmit} disabled={!myProfile.isSavable() || !myProfile.isDirty()}>
                        Save
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

ProfileFormDialog.propTypes = {
    profile: PropTypes.instanceOf(Profile),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ProfileFormDialog;
