import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {Button, Stack} from "@mui/joy";

import Header from "components/core/Header";
import SectionForm from "components/section/SectionForm";
import CommonUtils from "utils/CommonUtils";
import sectionActions from "actions/sectionActions";
import { Section } from 'domain';

const SectionEditor = ({ section, onClose }) => {
    const dispatch = useDispatch();
    const [mySection, setSection] = useState(new Section(section));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setSection(mySection.set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await mySection.inspect();

            CommonUtils.dispatcher(
                sectionActions.save(mySection),
                dispatch,
                'Section Updated',
                'Failed To Update Section'
            ).then(() => {
                onClose();
            });
        } catch (err) {
            const newErrors = err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
        }
    };

    const handleCancel = () => {
        mySection.reset();
        onClose();
    }

    return (
        <>
            <Header>
                Edit Section: {mySection.name}
            </Header>
            <SectionForm
                section={mySection}
                onChange={handleChange}
                onSave={handleSubmit}
                errors={errors}
            />
            <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
                <Button variant="plain" color="neutral" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    variant="solid"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!mySection.isSavable() || !mySection.isDirty()}
                >
                    Save
                </Button>
            </Stack>
        </>
    );
};

SectionEditor.propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    onClose: PropTypes.func.isRequired
};

export default SectionEditor;
