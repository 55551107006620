import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardOverflow, Typography, Avatar } from '@mui/joy';
import SchoolIcon from '@mui/icons-material/Bookmark';

const SectionListItem = ({ section, onClick }) => {
    return (
        <Card
            variant="outlined"
            color="primary"
            orientation="horizontal"
            sx={{
                width: {xs: '100%', md: 450 },
                maxWidth: {xs: '100%', md: '100%' },
                cursor: onClick ? 'pointer' : 'default',
                '&:hover': onClick ? { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' } : null,
            }}
            onClick={onClick ? () => onClick(section) : null}
        >
            <CardOverflow variant="soft" color="primary">
                <Avatar
                    variant="solid"
                    color="primary"
                    sx={{
                        width: 48,
                        height: 48,
                        ml: 1,
                        mr: 2,
                    }}
                >
                    <SchoolIcon />
                </Avatar>
            </CardOverflow>
            <CardContent>
                <Typography level="title-lg" color="primary">
                    {section.name}
                </Typography>
                <Typography level="body-sm" color="neutral">
                    {section.summary}
                </Typography>
            </CardContent>
        </Card>
    );
};

SectionListItem.propTypes = {
    section: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default SectionListItem;
