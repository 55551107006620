import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, FormLabel, Textarea, Typography } from '@mui/joy';

const ProfileUpgradeRequestForm = ({ onChange, message }) => {
    return (
        <Stack sx={{ gap: 2, width: '100%' }}>
            <Typography level="title-md" component="div">Account Upgrade Request</Typography>
            <Typography level="body-sm">
                To upgrade to a tutor, your request will be reviewed and approved by an administrator. Please make
                sure your profile is updated and your bio is filled out to aid the administrator in reviewing your
                application. You can include any pertinent information in the message below to support your request.
            </Typography>

            <FormControl>
                <FormLabel>Message to Administrator</FormLabel>
                <Textarea
                    placeholder="Enter your message here"
                    value={message}
                    onChange={(e) => onChange(e.target.value)}
                    minRows={3}
                    maxRows={8}
                />
            </FormControl>
        </Stack>
    );
};

ProfileUpgradeRequestForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    message: PropTypes.string,
};

export default ProfileUpgradeRequestForm;
