import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Select,
    Option,
    Sheet,
    Typography,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    Divider,
    Box,
} from '@mui/joy';
import SettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SendIcon from '@mui/icons-material/Send';

import profileActions from 'actions/profileActions';
import messageActions from 'actions/messageActions';
import CommonUtils from 'utils/CommonUtils';
import { Profile, Message } from 'domain';
import PropTypes from "prop-types";

const ProfileAdminPanel = ({ profile, me }) => {
    const dispatch = useDispatch();
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    if (me?.type !== Profile.TYPE_ADMIN || me?.id === profile?.id) {
        return null;
    }

    const handleProfileTypeChange = (e, value) => {
        const updatedProfile = profile.set('type', value);
        CommonUtils.dispatcher(
            profileActions.save(updatedProfile),
            dispatch,
            'Profile Updated',
            'Failed To Update Profile'
        );
    };

    const handleProfileStatusChange = (e, value) => {
        const updatedProfile = profile.set('status', value);
        CommonUtils.dispatcher(
            profileActions.save(updatedProfile),
            dispatch,
            'Profile Updated',
            'Failed To Update Profile'
        );
    };

    const handleSendMessage = () => {
        setIsSending(true);
        const messageData = new Message({
            to: profile.user,
            from: me.user,
            subject,
            body: message
        });
        CommonUtils.dispatcher(
            messageActions.save(messageData),
            dispatch,
            'Message Sent',
            'Failed To Send Message'
        ).then(() => {
            setSubject('');
            setMessage('');
            setIsSending(false);
        });
    };

    return (
        <Sheet
            variant="soft"
            color="warning"
            sx={{ p: 2, mt: 4}}
        >
            <Typography level="title-lg" component="h3" color="warning">
                <SettingsIcon sx={{ mr: 1, verticalAlign: 'middle', fontSize: 48 }} color="warning" />
                Profile Administration
            </Typography>
            <Typography level="body-sm" sx={{ mt: 1, mb: 2 }} color="warning">
                Use this panel to update the user&apos;s profile type and status. Select the appropriate type and
                status from the dropdowns below. Changes will be applied immediately.
            </Typography>
            <Grid spacing={2} p={2} container>
                <Grid xs={12} sm={6}>
                    <FormControl color="warning">
                        <FormLabel sx={{ color: 'var(--joy-palette-warning-500)' }}>Type</FormLabel>
                        <Select
                            color="warning"
                            value={profile.type}
                            onChange={handleProfileTypeChange}
                        >
                            <Option value={Profile.TYPE_STUDENT} color="warning">{Profile.TYPE_LABELS[Profile.TYPE_STUDENT]}</Option>
                            <Option value={Profile.TYPE_TUTOR} color="warning">{Profile.TYPE_LABELS[Profile.TYPE_TUTOR]}</Option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6}>
                    <FormControl color="warning">
                        <FormLabel sx={{ color: 'var(--joy-palette-warning-500)' }}>Status</FormLabel>
                        <Select
                            color="warning"
                            value={profile.status}
                            onChange={handleProfileStatusChange}
                        >
                            {Profile.STATUSES.map((status) => (
                                <Option key={status} value={status} color="warning">{Profile.STATUS_LABELS[status]}</Option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
            <Typography level="title-md" sx={{ my: 2 }} color="warning">
                Send User Direct Message
            </Typography>
            <Sheet sx={{ p: 2 }} variant="outlined">
                <FormControl sx={{ mt: 1 }}>
                    <FormLabel>Subject</FormLabel>
                    <Input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel>Message</FormLabel>
                    <Textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        minRows={3}
                    />
                </FormControl>
                <Box mt={1} align="right">
                    <Button
                        startDecorator={<SendIcon />}
                        loading={isSending}
                        loadingIndicator="Sending..."
                        disabled={!subject || !message}
                        color="neutral"
                        variant="solid"
                        onClick={handleSendMessage}
                    >
                        Send Message
                    </Button>
                </Box>
            </Sheet>
        </Sheet>
    );
};

ProfileAdminPanel.propTypes ={
    profile: PropTypes.instanceOf(Profile).isRequired,
    me: PropTypes.instanceOf(Profile).isRequired,
}

export default ProfileAdminPanel;

