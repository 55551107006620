import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, Link } from '@mui/joy';

import { MessageArray, Profile } from "domain";
import MessageTableRow from './MessageTableRow';
import { stableSort, getComparator } from "utils/SortUtils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const MessageTable = ({ messages, me, onClick }) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('sent');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headers = [
        { field: 'from', label: 'From', width: null },
        { field: 'to', label: 'To', width: null },
        { field: 'sent', label: 'Sent', width: null },
        { field: 'subject', label: 'Subject', width: '35%' },
    ];

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table hoverRow stickyHeader>
                <thead>
                    <tr>
                        {headers.map((th) => {
                            const active = orderBy === th.field;
                            return (
                                <th key={th.field} style={{width: th.width}}>
                                    <Link
                                        variant="plain"
                                        color="neutral"
                                        underline="none"
                                        component="button"
                                        onClick={() => handleRequestSort(th.field)}
                                        endDecorator={<ArrowDownwardIcon sx={{opacity: active ? 1 : 0}}/>}
                                        sx={{
                                            '& svg': {
                                                transition: '0.2s',
                                                transform:
                                                    active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                            },
                                            '&:hover': {'& svg': {opacity: 1}},
                                        }}
                                    >
                                        {th.label}
                                    </Link>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {stableSort(messages, getComparator(order, orderBy)).map((message) => (
                        <MessageTableRow key={message.id} message={message} me={me} onClick={onClick} />
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

MessageTable.propTypes = {
    messages: PropTypes.instanceOf(MessageArray).isRequired,
    me: PropTypes.instanceOf(Profile).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MessageTable;
