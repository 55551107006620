import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    Stack,
    FormControl,
    FormLabel,
    Input,
    Select,
    FormHelperText,
    Option,
    ListItemDecorator,
    Avatar,
    Box,
    Button,
} from '@mui/joy';
import CourseIcon from "@mui/icons-material/Language";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";

import { Course } from 'domain';
import RequiredIndicator from "components/core/RequiredIndicator";
import RichTextarea from "components/core/RichTextarea";

const CourseForm = ({ onSave, onChange, course, errors }) => {
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/course');
    };

    const handleDescriptionChange = (markdown) => {
        onChange('description')(null, markdown);
    };

    const renderLanguageValue = ({value}) => (
        <Box display="flex" alignItems="center" gap={1}>
            <Avatar size="sm" src={`https://unpkg.com/language-icons/icons/${value.toLowerCase()}.svg`}/>
            {Course.LANGUAGE_LABELS[value]}
        </Box>
    );

    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <FormControl error={!!errors.name}>
                <FormLabel>Name<RequiredIndicator /></FormLabel>
                <Input
                    placeholder="Enter the course name."
                    value={course.name}
                    onChange={onChange('name')}
                    error={!!errors.name}
                />
                <FormHelperText>{errors.name}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.tagline}>
                <FormLabel>Tagline<RequiredIndicator /></FormLabel>
                <Input
                    placeholder="A brief tagline for the course."
                    value={course.tagline}
                    onChange={onChange('tagline')}
                    error={!!errors.tagline}
                />
                <FormHelperText>{errors.tagline}</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel>Description<RequiredIndicator /></FormLabel>
                <RichTextarea
                    value={course.description}
                    onChange={handleDescriptionChange}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Language<RequiredIndicator /></FormLabel>
                <Select
                    value={course.language}
                    onChange={onChange('language')}
                    renderValue={renderLanguageValue}
                >
                    {Course.LANGUAGES.map((languageCode) => (
                        <Option key={languageCode} value={languageCode}>
                            <ListItemDecorator>
                                <Avatar
                                    variant="soft"
                                    size="sm"
                                    color="neutral"
                                    src={languageCode.toLowerCase() !== 'asl' ? `https://unpkg.com/language-icons/icons/${languageCode.toLowerCase()}.svg` : null}
                                >
                                    {languageCode.toLowerCase() !== 'asl' ? <CourseIcon/> : <SignLanguageIcon />}
                                </Avatar>
                            </ListItemDecorator>
                            {Course.LANGUAGE_LABELS[languageCode]}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="plain" color="neutral" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="solid" color="primary" onClick={onSave} disabled={!course.isSavable()}>
                    Save
                </Button>
            </Stack>
        </Stack>
    );
};

CourseForm.propTypes = {
    course: PropTypes.instanceOf(Course),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default CourseForm;
