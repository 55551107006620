import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Stack, Chip} from '@mui/joy';

import {Course, Material, Profile} from 'domain';
import Header from "components/core/Header";

import subDisplays from './display';
import MaterialActionMenu from './MaterialActionMenu';

const MaterialDetails = ({ material, course, onEdit }) => {
    const me = useSelector(({ profile }) => profile.me);
    const tutorProfile = course ? new Profile(course.get('tutor')?.get('profile')) : null;
    const isEditable = me?.type === Profile.TYPE_ADMIN || me?.id === tutorProfile?.id;
    const TypeDisplay = subDisplays[material.type];

    return (
        <Stack gap={2} style={{ height: '100%' }}>
            <Header>
                {material.name}
                {isEditable && (
                    <MaterialActionMenu material={material} onEdit={onEdit} />
                )}
            </Header>
            <Stack gap={2} direction="row">
                <Chip
                    color="primary"
                    disabled={material.status === Material.STATUS_INACTIVE}
                    variant={material.status === Material.STATUS_INACTIVE ? "solid" : "soft"}
                >
                    {Material.TYPE_LABELS[material.type]}
                </Chip>
                {material.status === Material.STATUS_INACTIVE && <Chip color="danger">Archived</Chip>}
            </Stack>
            {TypeDisplay && <TypeDisplay material={material}/>}
        </Stack>
    );
};

MaterialDetails.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    course: PropTypes.instanceOf(Course),
    onEdit: PropTypes.func.isRequired,
};

MaterialDetails.defaultProps = {
    course: null,
};
export default MaterialDetails;
