import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, FormLabel, Input, FormHelperText } from '@mui/joy';

import { Profile } from 'domain';
import RequiredIndicator from "components/core/RequiredIndicator";
import RichTextarea from "components/core/RichTextarea";

const ProfileForm = ({ onSave, onChange, profile, errors }) => {
    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <FormControl error={!!errors.name}>
                <FormLabel>Name<RequiredIndicator /></FormLabel>
                <Input
                    placeholder="Please provide a display name."
                    value={profile.name}
                    onChange={onChange('name')}
                    error={!!errors.name}
                />
                <FormHelperText>{errors.name}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.tagline}>
                <FormLabel>Tagline</FormLabel>
                <Input
                    placeholder={`A snappy line for ${profile.name || 'this profile'} (optional).`}
                    value={profile.tagline}
                    onChange={onChange('tagline')}
                    error={!!errors.tagline}
                />
                <FormHelperText>{errors.tagline}</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel>Bio</FormLabel>
                <RichTextarea
                    value={profile.bio}
                    onChange={(markdown) => {
                        onChange('bio')(null, markdown);
                    }}
                />
            </FormControl>
        </Stack>
    );
};

ProfileForm.propTypes = {
    profile: PropTypes.instanceOf(Profile),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default ProfileForm;
