import React from 'react';
import PropTypes from 'prop-types';
import {Material} from "domain";
import {Sheet} from "@mui/joy";
import DOMPurify from "dompurify";

const DocumentDisplay = ({ material }) => (
    <Sheet sx={{px: 2}}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(material.data.content ?? '') }} />
    </Sheet>
);

DocumentDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default DocumentDisplay;
