import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Avatar } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';

import SectionFormDialog from "components/section/SectionFormDialog";
import {Course, Section} from "domain";
import CommonUtils from "utils/CommonUtils";
import sectionActions from "actions/sectionActions";

const SectionListAdd = ({ course }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async (section) => {
        section.set('course', course);
        CommonUtils.dispatcher(
            sectionActions.save(section),
            dispatch,
            'Section Created',
            'Failed To Create Section'
        ).then(() => {
            handleClose();
            navigate(`/course/${course.id}/section/${section.id}`);
        });
    };

    return (
        <>
            <Card
                variant="outlined"
                color="neutral"
                orientation="horizontal"
                sx={{
                    width: {xs: '100%', md: 320 },
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                }}
                onClick={handleOpen}
            >
                <Avatar
                    variant="soft"
                    color="neutral"
                    sx={{
                        width: 48,
                        height: 48,
                        ml: 1,
                        mr: 2,
                    }}
                >
                    <AddIcon color="neutral" />
                </Avatar>
                <CardContent>
                    <Typography level="title-lg" color="neutral">
                        Add Section
                    </Typography>
                    <Typography level="body-sm" color="neutral">
                        Add a section to this course.
                    </Typography>
                </CardContent>
            </Card>

            <SectionFormDialog
                open={open}
                onClose={handleClose}
                onSave={handleSubmit}
                section={new Section({ course })}
            />
        </>
    );
};

SectionListAdd.propTypes = {
    course: PropTypes.instanceOf(Course).isRequired,
};

export default SectionListAdd;
