import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import Header from "components/core/Header";
import CourseForm from "components/course/CourseForm";
import CommonUtils from "utils/CommonUtils";
import courseActions from "actions/courseActions";
import {Course} from 'domain';
import PropTypes from "prop-types";

const CourseEditor = ({ course, onClose }) => {
    const dispatch = useDispatch();
    const [myCourse, setCourse] = useState(new Course(course));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setCourse(myCourse.set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await myCourse.inspect();

            CommonUtils.dispatcher(
                courseActions.save(myCourse),
                dispatch,
                'Course Updated',
                'Failed To Update Course'
            ).then(() => {
                onClose();
            });
        } catch (err) {
            const newErrors = err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
        }
    };

    return (
        <>
            <Header>
                {myCourse.name}
            </Header>
            <CourseForm course={myCourse} onChange={handleChange} onSave={handleSubmit} errors={errors} />
        </>
    );
}

CourseEditor.propTypes = {
    course: PropTypes.instanceOf(Course).isRequired,
    onClose: PropTypes.func.isRequired
};

export default CourseEditor;
