import { Material } from 'domain';

import DocumentForm from './DocumentForm';
import FormForm from './FormForm';
import AttachmentForm from './AttachmentForm';
import PdfForm from './PdfForm';
import ImageForm from './ImageForm';
import VideoForm from './VideoForm';
import WebURLForm from './WebURLForm';

export default {
    [Material.TYPE_DOC]: DocumentForm,
    [Material.TYPE_FRM]: FormForm,
    [Material.TYPE_ATC]: AttachmentForm,
    [Material.TYPE_PDF]: PdfForm,
    [Material.TYPE_IMG]: ImageForm,
    [Material.TYPE_WVD]: VideoForm,
    [Material.TYPE_WEB]: WebURLForm,
};
