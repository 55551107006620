import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Stack } from "@mui/joy";

import Header from "components/core/Header";
import MaterialForm from "components/material/MaterialForm";
import CommonUtils from "utils/CommonUtils";
import materialActions from "actions/materialActions";
import { Material } from 'domain';

const MaterialEditor = ({ material, onClose }) => {
    const dispatch = useDispatch();
    const [myMaterial, setMaterial] = useState(new Material(material));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setMaterial(myMaterial.set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await myMaterial.inspect();

            CommonUtils.dispatcher(
                materialActions.save(myMaterial),
                dispatch,
                'Material Updated',
                'Failed To Update Material'
            ).then(() => {
                onClose();
            });
        } catch (err) {
            const newErrors = err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
        }
    };

    const handleCancel = () => {
        myMaterial.reset();
        onClose();
    }

    return (
        <>
            <Header>
                {myMaterial.name}
            </Header>
            <MaterialForm
                material={myMaterial}
                onChange={handleChange}
                onSave={handleSubmit}
                errors={errors}
            />
            <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
                <Button variant="plain" color="neutral" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    variant="solid"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!myMaterial.isSavable() || !myMaterial.isDirty()}
                >
                    Save
                </Button>
            </Stack>
        </>
    );
};

MaterialEditor.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onClose: PropTypes.func.isRequired
};

export default MaterialEditor;
