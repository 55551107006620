import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import { Sheet, Button, Stack, Typography } from '@mui/joy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Material, Notice } from 'domain';
import FileUploader from 'components/core/FileUploader';
import appActions from "actions/appActions";

const MAX_SIZE = 2097152; // 2mb

const ImageForm = ({ material, onChange }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const fileName = material?.data?.name ?? material?.attachment?.name();

    const handleUpload = (parseFile, fileMetadata) => {
        setIsLoading(false);
        onChange('attachment', parseFile);
        onChange('data', fileMetadata);
    };

    const handleUploadClick = () => {
        setIsLoading(true);
    };

    const handleError = (error) => {
        dispatch(appActions.addNotice(new Notice({ title: error, type: Notice.TYPE_ERROR })))
        setIsLoading(false);
    }

    return (
        <Stack gap={2}>
            <Typography level="body-sm">
                Upload an image to be viewed by your students. There is a <strong>2mb file size limit</strong>. For
                larger files, consider using Dropbox or Google Drive and link with a &quot;Web URL&quot; material type.
            </Typography>
            <Sheet sx={{ p: 2, textAlign: "center" }} color="primary">
                {fileName && (
                    <Typography level="h5" mb={2}>
                        {fileName}
                    </Typography>
                )}
                <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<CloudUploadIcon />}
                    loading={isLoading}
                    loadingPosition="start"
                    onClick={handleUploadClick}
                >
                    {material?.attachment ? 'Replace' : 'Upload'}
                </Button>
            </Sheet>
            <FileUploader
                open={isLoading}
                onUpload={handleUpload}
                onError={handleError}
                types={['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']}
                maxSize={MAX_SIZE}
            />
        </Stack>
    );
};

ImageForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ImageForm;
