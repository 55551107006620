import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sheet } from '@mui/joy';

import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";

import "survey-creator-core/survey-creator-core.min.css";
import 'survey-core/defaultV2.min.css';
import "components/material/display/FormDisplay.css";

import { Material } from 'domain';

const FormForm = ({ material, onChange }) => {
    const creatorOptions = {
        showJSONEditorTab: true,
        isAutoSave: true
    };
    const creator = new SurveyCreator(creatorOptions);
    creator.saveSurveyFunc = (saveNo, callback) => {
        callback(saveNo, true);
        onChange('data', creator.JSON);
    }

    useEffect(() => {
        creator.JSON = material.data;
    }, [material.data])

    return (
        <Sheet variant="outlined" sx={{ overflow: 'hidden', borderRadius: 'md' }}>
            <SurveyCreatorComponent creator={creator} />
        </Sheet>
    );
};

FormForm.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default FormForm;
