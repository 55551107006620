import React from 'react';
import { Button, Stack, Typography } from '@mui/joy';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import CommonUtils from "utils/CommonUtils";
import PropTypes from "prop-types";
import {Material} from "domain";

const AttachmentDisplay = ({ material }) => (
    <Stack textAlign="center">
        <Button
            component="a"
            variant="solid"
            color="primary"
            href={material.attachment?.url()}
            size="lg"
            target="_blank"
            startDecorator={<CloudDownloadIcon />}
        >
            Download
        </Button>
        <Typography level="body-xs" color="neutral" my={1}>
            {`${material?.data?.name ?? material?.attachment?.name()} (${CommonUtils.formatSizeUnits(material.data?.size)})`}
        </Typography>
    </Stack>
);

AttachmentDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default AttachmentDisplay;
