import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from "dompurify";
import { Box, Sheet, Stack, IconButton, Typography } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';

import {Section, MaterialArray, Course} from "domain";
import Header from "components/core/Header";
import MaterialList from 'components/material/MaterialList';

const SectionDetails = ({ course, section, materials, onEdit, onOpen }) => {
    const { name, summary, description } = section;
    const descriptionHtml = DOMPurify.sanitize(description ?? '');

    return (
        <Stack gap={2}>
            <Header>
                {name}
                {onEdit && (
                    <Box>
                        <IconButton onClick={onEdit}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                )}
            </Header>
            <Typography level="title-md">{summary}</Typography>
            <Sheet variant="soft" sx={{ px: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            </Sheet>

            {((materials && materials.length > 0) || onEdit) && (
                <>
                    <Typography level="title-lg" mt={2}>
                        Materials
                    </Typography>
                    <MaterialList
                        materials={materials}
                        course={course}
                        section={section}
                        onClick={onOpen}
                        enableAdd={onEdit != null}
                    />
                </>
            )}
        </Stack>
    );
}

SectionDetails.propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    course: PropTypes.instanceOf(Course),
    materials: PropTypes.instanceOf(MaterialArray),
    onEdit: PropTypes.func,
    onOpen: PropTypes.func,
};

SectionDetails.defaultProps = {
    course: null,
    materials: null,
    onEdit: null,
    onOpen: null,
};

export default SectionDetails;
