import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/joy';

import {Course, Material, Section} from 'domain';
import MaterialListItem from './MaterialListItem';
import MaterialListAdd from "components/material/MaterialListAdd";

const MaterialList = ({ materials, course, section, onClick, enableAdd }) => {
    if ((!materials || materials.length === 0) && !enableAdd) {
        return (
            <Typography level="body-sm" my={2} color="neutral">
                No materials available.
            </Typography>
        );
    }

    return (
        <Stack gap={1}>
            {materials?.map((material) => (
                <MaterialListItem key={material.id} material={material} onClick={onClick} />
            ))}
            {enableAdd && <MaterialListAdd course={course} section={section} />}
        </Stack>
    );
};

MaterialList.propTypes = {
    materials: PropTypes.arrayOf(PropTypes.instanceOf(Material)),
    course: PropTypes.instanceOf(Course).isRequired,
    section: PropTypes.instanceOf(Section).isRequired,
    onClick: PropTypes.func,
    enableAdd: PropTypes.bool,
};

MaterialList.defaultProps = {
    sections: null,
    onClick: null,
    enableAdd: false,
};

export default MaterialList;
