import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {Dropdown, IconButton, Menu, MenuButton, MenuItem} from '@mui/joy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import SubscriptionIcon from '@mui/icons-material/CardMembership';

import {Profile, Notice, Message} from 'domain';
import messageActions from 'actions/messageActions';
import profileActions from 'actions/profileActions';
import CommonUtils from "utils/CommonUtils";
import appActions from "actions/appActions";
import FileUploader from 'components/core/FileUploader';

import ProfileFormDialog from './ProfileFormDialog';
import ProfileUpgradeRequestFormDialog from './ProfileUpgradeRequestFormDialog';
import ColorSchemeMenuItem from "components/core/ColorSchemeMenuItem";

const PROFILE_PIC_MAX_SIZE = 3145728; // 3mb

const ProfileActionMenu = ({ profile }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myProfile = useSelector(({ profile }) => profile.me);
    const me = useSelector(({ auth }) => auth.me);
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isUpgradeRequestOpen, setIsUpgradeRequestOpen] = useState(false);

    const isMyProfile = myProfile.id === profile.id;
    const isAdmin = myProfile.type === Profile.TYPE_ADMIN;

    const handleEdit = () => {
        setIsDialogOpen(true);
    };

    const handleSave = (updatedProfile) => {
        CommonUtils.dispatcher(
            profileActions.save(updatedProfile),
            dispatch,
            'Profile Saved',
            'Failed To Save Profile'
        );
        setIsDialogOpen(false);
    };

    const handleUpgradeRequestOpen = () => {
        setIsUpgradeRequestOpen(true);
    };

    const handleUpgradeRequestClose = () => {
        setIsUpgradeRequestOpen(false);
    };

    const handleUpgradeRequestSubmit = (message) => {
        const formattedMessage = message.split('\n').map(line => `> ${line}`).join('\n');

        CommonUtils.dispatcher(
            messageActions.save(new Message({
                group: 'ADMIN',
                from: me,
                subject: 'Tutor Upgrade Request',
                body: `This user has requested being upgraded to a tutor.\n\n**Message:**\n${formattedMessage}\n\n`,
            })),
            dispatch,
            'Request Sent',
            'Failed To Send Request'
        );
        setIsUpgradeRequestOpen(false);
    };

    const handleEditPicture = () => {
        setIsUploaderOpen(true);
    };

    const handleUpload = async (parseFile) => {
        setIsUploaderOpen(false);
        profile.set('pic', parseFile);
        handleSave(profile);
    };

    const handleUploadFailed = (error) => {
        dispatch(appActions.addNotice(new Notice({title: error, type: Notice.TYPE_ERROR})))
    }

    const handleOpenSubscription = () => {
        navigate('/subscription');
    }

    // Check if the user is not an admin or not viewing their own profile
    if (!(myProfile && (isAdmin || isMyProfile))) {
        return null;
    }

    return (
        <>
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                >
                    <MoreVertIcon />
                </MenuButton>
                <Menu>
                    <MenuItem onClick={handleEdit}>
                        <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem onClick={handleEditPicture}>
                        <PhotoCameraIcon /> Change Picture
                    </MenuItem>
                    {isMyProfile && myProfile.type === Profile.TYPE_STUDENT && (
                        <MenuItem onClick={handleUpgradeRequestOpen}>
                            <UpgradeIcon /> Upgrade to Tutor
                        </MenuItem>
                    )}
                    {isMyProfile && myProfile.type === Profile.TYPE_TUTOR && (
                        <MenuItem onClick={handleOpenSubscription}>
                            <SubscriptionIcon /> Subscription
                        </MenuItem>
                    )}
                    <ColorSchemeMenuItem />
                </Menu>
            </Dropdown>
            <ProfileFormDialog
                key={profile.id}
                profile={profile}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
            />
            <ProfileUpgradeRequestFormDialog
                open={isUpgradeRequestOpen}
                onClose={handleUpgradeRequestClose}
                onSubmit={handleUpgradeRequestSubmit}
            />
            <FileUploader
                open={isUploaderOpen}
                onUpload={handleUpload}
                onError={handleUploadFailed}
                types={['image/jpeg', 'image/png', 'image/gif']}
                maxSize={PROFILE_PIC_MAX_SIZE}
            />
        </>
    );
};

ProfileActionMenu.propTypes = {
    profile: PropTypes.instanceOf(Profile).isRequired,
};

export default ProfileActionMenu;
