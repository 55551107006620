import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from "dompurify";
import {marked} from "marked";
import {Chip, Stack, Sheet, Grid, Typography, Avatar} from "@mui/joy";

import {Message, Profile} from "domain";
import Header from "components/core/Header";
import ProfileChip from "components/core/ProfileChip";
import CommonUtils from "utils/CommonUtils";

const MessageDetails = ({ message }) => {
    const { from, to, group, sent, subject, body } = message;
    const bodyHtml = DOMPurify.sanitize(marked.parse(body ?? '', { gfm: true, breaks: true}));

    const fromProfile = from && new Profile(from?.get('profile'));
    const toProfile = to && new Profile(to?.get('profile'));
    const toGroup = group?.get('name');

    const fromChip = fromProfile && <ProfileChip profile={fromProfile} />
    const toChip = toProfile && <ProfileChip profile={toProfile} />
    const groupChip = toGroup && (
        <Chip
            variant="outlined"
            sx={{
                "--Chip-decoratorChildHeight": "32px",
                "--Chip-minHeight": "42px"
            }}
            startDecorator={
                <Avatar variant="soft">
                    {CommonUtils.getInitials(toGroup)}
                </Avatar>
            }
        >
            {toGroup}
        </Chip>
    )

    return (
        <Stack gap={0}>
            <Header>{subject}</Header>
            <Typography level="body-sm">{CommonUtils.formatDateTime(sent)}</Typography>
            <Grid container my={2}>
                <Grid xs={12} sm={6}>
                    <Typography component="div" level="body-xs">
                        From
                    </Typography>
                    {fromChip}
                </Grid>
                <Grid xs={12} sm={6}>
                    <Typography component="div" level="body-xs">
                        To
                    </Typography>
                    {toChip}
                    {groupChip}
                </Grid>
            </Grid>
            <Sheet variant="soft" sx={{ p: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
            </Sheet>
        </Stack>
    );
}

MessageDetails.propTypes = {
    message: PropTypes.instanceOf(Message),
};

export default MessageDetails;
