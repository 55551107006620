import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';

import {Material} from "domain";

import 'survey-core/defaultV2.min.css';
import "components/material/display/FormDisplay.css";

const FormDisplay = ({material}) => {
    const surveyComplete = useCallback((sender) => {
        // eslint-disable-next-line no-console
        console.warn('===>', { 'sender.data': sender.data })
    }, []);

    const survey = new Model(material.data);
    survey.onComplete.add(surveyComplete);

    return <Survey model={survey} />;

}

FormDisplay.propTypes = {
    material: PropTypes.instanceOf(Material).isRequired,
};

export default FormDisplay;
